//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from "@/layouts/Base";
import { AUTH_REQUEST } from "@/store/actions";
import settings from "@/settings";
export default {
  name: "login-page",
  components: {
    BaseLayout,
  },
  metaInfo: {
    title: "Login",
  },
  data() {
    return {
      isPwd: true,
      error_msg: "",
      loading: false,
      login_error: {
        email: "",
        password: "",
      },
      login_data: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    has_history() {
      return window.history.length > 2;
    },
    google() {
      return window.google;
    },
    client_id() {
      return settings.GOOGLE_CLIENT_ID;
    }
  },
  mounted() {
    this.initClient()
  },
  methods: {
    handleCredentialResponse(response) {
      if (response.credential) {
        var code = response.credential;
        var id_token = response.credential;
        this.trigger_login({ code, id_token });
      }
    },
    initClient() {
      this.google.accounts.id.initialize({
        client_id: this.client_id,
        callback: this.handleCredentialResponse,
        scope: 'profile email https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.birthday.read',
      });

      this.google.accounts.id.renderButton(
        this.$refs.g_id_signin,
        {
          theme: "filled_blue",
          size: "large",
          shape: "pill",
          type: "standard",
          text: "continue_with",
          logo_alignment: "left",
        }
      );
      this.google.accounts.id.prompt();
    },
    startLogin() {
      this.loading = true;

      // window.gapi.load("auth2", () => {

      //   window.gapi.auth2.authorize(
      //     {
      //       clientId: settings.GOOGLE_CLIENT_ID,
      //       scope:
      //         "profile email https://www.googleapis.com/auth/user.phonenumbers.read https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.birthday.read",
      //       prompt: "select_account",
      //       response_type: "id_token code",
      //     },
      //     (response) => {
      //       this.loading = false;
      //       console.log(response);
      //       if (response.error) {
      //         console.log(response.error);
      //         this.error_msg = "An Unexpected error was encountered";
      //         return;
      //       }
      //       this.trigger_login({
      //         id_token: response.id_token,
      //         code: response.code,
      //       });
      //     }
      //   );
      // });
    },
    clear_errors() {
      this.login_error.email = "";
      this.login_error.password = "";
      this.error_msg = "";
    },
    attempt_login() {
      this.clear_errors();
      var payload = {
        username: this.login_data.email.toLowerCase(),
        password: this.login_data.password,
      };
      this.trigger_login(payload);
    },
    trigger_login(payload) {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(AUTH_REQUEST, payload)
        .then(() => {
          this.loading = false;
          if (this.has_history && !this.$route.query.next) {
            this.$router.go(-1);
          } else this.$router.push({ name: this.$route.query.next || "home" });
        })
        .catch((error) => {
          this.loading = false;
          var got_err_msg = false;
          if (error.response && error.response.data) {
            got_err_msg = this.check_fields_for_error(
              error.response.data,
              this.login_error,
              ["email", "password"]
            );
          }
          if (!got_err_msg) this.error_msg = this.decode_error_message(error);
        });
    },
  },
};
